// react imports
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
// component imports via lazy loading
const Layout = lazy(() => import("./components/Layout"));
const ErrorPage = lazy(() => import("./components/ErrorPage/ErrorPage"));

interface IAppProps {}

const App = (props: IAppProps) => {
  const renderLoader = () => <h1>Loader ....</h1>;

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 2000,
        }}
      />
      <Suspense fallback={renderLoader()}>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
